import DELETE_EXPERIMENT from '@/graphql/DeleteExperiment.gql';
import moment from 'moment';

export default {
  mounted() {
    this.$bus.$on('experiments:deleteExperiment', this.handleDeleteExperiment);
  },
  beforeDestroy() {
    this.$bus.$off('experiments:deleteExperiment');
  },
  methods: {
    deleteExperiment(experimentId, { isDetailsPage }) {
      this.$modal.show('delete-experiment', {
        experimentId,
        isDetailsPage,
      });
    },
    handleDeleteExperiment({ experimentId, isDetailsPage }) {
      this.$apollo
        .mutate({
          mutation: DELETE_EXPERIMENT,
          variables: {
            experimentId,
          },
        })
        .then(({ data }) => {
          if (data?.deleteExperiment?.success) {
            if (!isDetailsPage) {
              const experimentIndex = this.experiments.findIndex(
                (experiment) => experiment._id === experimentId,
              );
              this.experiments.splice(experimentIndex, 1);
              this.experimentCount -= 1;
            } else {
              this.$router.push({ name: 'experiments' });
            }
            this.$notify({
              type: 'success',
              text: this.$t('notifications.deleteSuccess'),
            });
            this.$modal.hide('dialog');
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.deleteError'),
            });
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.deleteError'),
          });
        });
    },
    getDuration(experiment) {
      if (experiment.status !== 'DRAFT') {
        const duration = experiment.finishedAt
          ? moment.utc(experiment.finishedAt).diff(moment.utc(experiment.startedAt), 'days') + 1
          : moment().diff(moment.utc(experiment.startedAt), 'days') + 1;
        return `${duration} ${this.$tc('experiments.days', duration)}`;
      }

      return null;
    },
    roundToNDecimals({ number, decimalPlaces }) {
      return Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    },
    convertDecimalsUp({ number, decimalPlaces }) {
      return number * Math.pow(10, decimalPlaces);
    },
    reduceToNthDecimal({ number, decimalPlaces, reduceDecimalCount }) {
      return this.roundToNDecimals({
        number: number / Math.pow(10, reduceDecimalCount),
        decimalPlaces,
      });
    },
    getTrafficShareInPercentage(trafficShare) {
      const groupCount = this.experiment.groups.length;
      return this.roundToNDecimals({
        number: this.convertDecimalsUp({ number: trafficShare, decimalPlaces: 2 }) / groupCount,
        decimalPlaces: 1,
      });
    },
  },
};
