<template lang="pug">
om-modal(
  name="new-experiment"
  modalClasses="om-name-campaign-modal"
  :width="500"
  :clickToClose="true"
  headerBorder
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
)
  template(slot="modal-header")
    .row
      .col
        om-heading(h5) {{ $t('experiments.modal.newExperiment.chooseDomain') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('new-experiment')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    domain-input-v2(
      @selectedDomain="onSelectedDomain"
      :savedExternally="saveBtnClickCounter"
      @saveEvent="createExperiment"
      :domainDeletable="domainDeletable"
    )
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button.mt-4(primary :loading="loading" @click="save" :data-track="'domain update'") {{ $t('next') }}
</template>
<script>
  import CREATE_EXPERIMENT from '@/graphql/CreateExperiment.gql';
  import DomainInputV2 from '@/components/DomainInputV2.vue';
  import { required } from 'vuelidate/lib/validators';
  import { mapGetters, mapState } from 'vuex';

  export default {
    components: {
      DomainInputV2,
    },

    props: {
      domainDeletable: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        selectedDomain: null,
        loading: false,
        saveBtnClicked: false,
        saveBtnClickCounter: 0,
        mode: null,
      };
    },

    computed: {
      ...mapGetters(['brandName']),
      ...mapState(['account']),
      isUpdateMode() {
        return this.mode === 'update';
      },
    },

    validations: {
      selectedDomain: {
        required,
      },
    },

    methods: {
      beforeOpen(event) {
        this.selectedDomain = null;
        this.saveBtnClicked = false;
        this.saveBtnClickCounter = 0;
        this.mode = event.params?.mode || null;

        if (this.isUpdateMode) {
          // remove ESC listener to prevent modal close
          window.removeEventListener('keyup', this.$children[0].$children[0].onEscapeKeyUp);
        }
      },

      beforeClose() {
        if (this.isUpdateMode) {
          // add ESC listener to restore og functionality
          window.addEventListener('keyup', this.$children[0].$children[0].onEscapeKeyUp);
        }
      },

      save() {
        if (this.saveBtnClicked) {
          return;
        }

        this.saveBtnClickCounter++;
      },
      async createExperiment() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.selectOrAddDomain'),
          });
          return;
        }

        this.saveBtnClicked = true;
        this.loading = true;
        const { _id: domainId } = this.selectedDomain;

        try {
          const {
            data: { experiment },
          } = await this.$apollo.mutate({
            mutation: CREATE_EXPERIMENT,
            variables: {
              domainId,
            },
          });

          this.$router.push({ name: 'experiment', params: { experimentId: experiment._id } });

          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.saveBtnClicked = false;
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
        }
      },
      onSelectedDomain(domain) {
        // reset double click detection on domain change
        this.saveBtnClicked = false;
        this.selectedDomain = domain;
      },
    },
  };
</script>
<style lang="sass">
  .om-name-campaign-modal
    .brand-modal-footer
      padding-top: 0 !important
    .brand-modal-body
      padding-left: 40px !important
      padding-right: 40px !important
      padding-bottom: 0 !important
</style>
