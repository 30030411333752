<template lang="pug">
.brand-wrapper.experiment-list
  .container-fluid.px-3
    .row.mb-6
      .col-9.align-self-center
        om-heading.mt-1(h1) {{ $t('experiments.title') }}
      .col-3.d-flex.justify-content-end.align-items-center
        om-button#new-experiment(primary @click="createNewExperiment") {{ $t('experiments.newExperiment') }}
    om-table.experiments-table.pt-6(
      :items="experiments"
      :columns="columns"
      :columnClasses="columnClasses"
      :loadingContent="loadingExperiments"
      :sortable="false"
      :selectable="false"
      :allItemsCount="experimentCount"
      :pagination="pagination"
      @paginationChange="paginationChange"
      v-if="!experimentLoadingError"
    )
      template(slot="loading")
        skeleton-loader(type="experiment" :rows="5")
      template(slot="name" slot-scope="{ rowData }")
        .d-flex.align-items-center
          router-link(:to="{ name: 'experiment', params: { experimentId: rowData.row._id } }") {{ rowData.row.name }}
      template(slot="status" slot-scope="{ rowData }")
        om-chip.m-0(small :style="{ width: '80px' }" :color="statusType(rowData.row.statusKey)") {{ rowData.row.status }}
      template(slot="delete" slot-scope="{ rowData }")
        om-button(
          ghost
          iconOnly
          small
          icon="trash-alt"
          @click="deleteExperiment(rowData.row._id, { isDetailsPage: false })"
        )
    template(v-if="experimentLoadingError")
      .brand-box.brand-box-gray.text-center.pt-5.pb-5.flex-wrap.justify-content-center.flex-column
        img(src="@/assets/admin/img/crying.png" style="width: 13.25rem; height: 11.875rem")
        span {{ $t('experiments.loadingError') }}
    .row
      description(:showNew="showNew")
  new-experiment(:domainDeletable="false")
  delete-experiment
</template>

<script>
  import GET_EXPERIMENTS from '@/graphql/GetExperiments.gql';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import dateFormat from '@/mixins/dateFormat';
  import experiments from '@/mixins/experiments';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import NewExperiment from '@/components/Modals/NewExperiment.vue';
  import Description from '@/components/Experiments/DescriptionModule.vue';
  import DeleteExperiment from '@/components/Experiments/Modal/DeleteExperiment.vue';
  import { UilTrashAlt } from '@iconscout/vue-unicons';
  import cookie from '@/mixins/cookie';

  const cookieKeySeenPage = 'x-om-seen-experiments-page';

  export default {
    components: {
      SkeletonLoader,
      NewExperiment,
      UilTrashAlt,
      Description,
      DeleteExperiment,
    },
    mixins: [dateFormat, experiments, cookie],
    data() {
      return {
        experiments: [],
        loadingExperiments: true,
        columnClasses: {
          name: 'align-left experiment-name',
          domain: 'align-left experiment-domain',
          status: 'align-left experiment-status',
          duration: 'align-left experiment-duration',
          createdAt: 'experiment-createdAt',
          delete: 'experiment-delete',
        },
        columns: [
          { header: this.$t('name'), key: 'name' },
          { header: this.$t('domain'), key: 'domain' },
          { header: this.$t('status'), key: 'status' },
          { header: this.$t('experiments.duration'), key: 'duration' },
          { header: this.$t('dateCreated'), key: 'createdAt' },
          { header: '', key: 'delete' },
        ],
        pagination: { page: 1, limit: 30 },
        experimentCount: 0,
        experimentLoadingError: false,
      };
    },
    computed: {
      ...mapGetters(['domains']),
      showNew() {
        return this.experiments.length < 1;
      },
    },
    async created() {
      await this.fetchExperiments();
    },
    mounted() {
      if (!this.getCookie(cookieKeySeenPage)) {
        this.$bus.$emit('newFeaturePageSeen');
      }

      const queryParams = this.$route.query;
      if (queryParams.startFlow) {
        this.$modal.show('new-experiment');
      }
    },
    methods: {
      statusType(key) {
        return key === 'running' ? 'green' : 'secondary';
      },
      async fetchExperiments() {
        this.experimentLoadingError = false;
        const {
          data: { experimentList },
        } = await this.$apollo.query({
          query: GET_EXPERIMENTS,
          variables: {
            pagination: this.pagination,
          },
        });

        if (experimentList) {
          const { experiments, experimentCount } = experimentList;

          this.experimentCount = experimentCount;
          this.experiments = experiments.map((experiment) => {
            return {
              ...experiment,
              domain:
                this.domains.find((domain) => domain._id === experiment.domainId)?.domain || '',
              statusKey: experiment.status.toLowerCase(),
              status: this.$t(`experiments.${experiment.status.toLowerCase()}`),
              duration: this.getDuration(experiment),
              createdAt: moment(experiment.createdAt).format(this.dateFormat),
            };
          });
        } else {
          this.experimentLoadingError = true;
        }
        this.loadingExperiments = false;
      },
      createNewExperiment() {
        this.$modal.show('new-experiment');
      },
      async paginationChange({ page, limit }) {
        this.pagination = { page, limit };
        await this.fetchExperiments();
      },
    },
  };
</script>
<style lang="sass">
  .experiment-list .brand-table .tbody .brand-table-tr
    min-height: 4.85714285714rem
</style>
