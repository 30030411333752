<template lang="pug">
om-modal(name="delete-experiment" @beforeOpen="beforeOpen" headerBorder :width="425" color="light")
  template.border-bottom(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('experiments.modal.deleteExperiment.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('delete-experiment')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    om-body-text(bt400md)
      span(v-html="$t('experiments.modal.deleteExperiment.description')")
  template(slot="modal-footer")
    .d-flex.align-items-center.justify-content-end
      om-button#cancel-delete-experiment.mr-3(ghost @click="skip") {{ $t('experiments.modal.deleteExperiment.cancelBtn') }}
      om-button#delete-experiment(primary @click="done") {{ $t('experiments.modal.deleteExperiment.yesBtn') }}
</template>
<script>
  export default {
    methods: {
      data() {
        return {
          experimentId: null,
          isDetailsPage: false,
        };
      },
      done() {
        this.$modal.hide('delete-experiment');
        this.$bus.$emit('experiments:deleteExperiment', {
          experimentId: this.experimentId,
          isDetailsPage: this.isDetailsPage,
        });
      },
      skip() {
        this.$modal.hide('delete-experiment');
      },
      beforeOpen(event) {
        const { isDetailsPage, experimentId } = event.params;
        this.experimentId = experimentId;
        this.isDetailsPage = isDetailsPage;
      },
    },
  };
</script>
