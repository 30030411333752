<template lang="pug">
.experiments-description.col-12.mt-6
  .experiments-description-left-shape
    img(:src="require('@/assets/admin/img/experiments/left_shape.svg')" style="height: 100%")
  .experiments-description-right-shape
    img(:src="require('@/assets/admin/img/experiments/right_shape.svg')")
  .row
    .col-12.col-lg-6.d-flex.align-items-center
      div(:class="{ 'extra-padding': !showNew }")
        om-heading.mb-3(h3 v-html="$t('experiments.description.title')")
        .experiments-desc {{ $t('experiments.description.text') }}
        .experiments-btns.mt-6
          om-button#new-experiment(
            primary
            small
            @click="createNewExperiment"
            v-if="showNew"
            icon="plus-circle"
          ) {{ $t('experiments.newExperiment') }}
          om-button(
            ghost
            small
            @click="readMore()"
            :class="{ 'mr-2': !showNew, 'mx-2': showNew }"
            icon="book-alt"
          ) {{ $t('experiments.readMore') }}
          om-button.mx-2(ghost small @click="watchVideo()" icon="play-circle") {{ $t('experiments.watchVideo') }}
    .col-6.d-none.d-lg-flex
      img.experiments-description-img(
        :src="require('@/assets/admin/img/experiments/multitouch-camp-ill.png')"
      )
</template>
<script>
  export default {
    props: {
      showNew: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      createNewExperiment() {
        this.$modal.show('new-experiment');
      },
      readMore() {
        const enLink = 'https://support.optimonk.com/hc/en-us/articles/8840643937554';
        const huLink = 'https://support.optimonk.hu/hc/hu/articles/9060824894866';
        const lang = this.$i18n.locale;
        const supportPageByLang = lang === 'hu' ? huLink : enLink;
        window.open(supportPageByLang, '_blank');
      },
      watchVideo() {
        window.open('https://www.youtube.com/watch?v=cd8_t9Eq-xg', '_blank');
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .experiments-btns
    display: flex
    align-items: center
    margin-top: 1rem


  .experiments-description
    background: #FFF7F2
    padding: 2rem
    border-radius: 8px
    position: relative
    overflow: hidden

    &-left-shape
      position: absolute
      left: -10px
      top: 0
      height: 100%
    &-right-shape
      position: absolute
      right: 185px
      bottom:0

    @media (max-width: 1200px)
      &-left-shape,
      &-right-shape
        display: none

    &-img
      position: absolute
      height: 200px
      right: 20px
      bottom: 0

    .experiments-desc
      color: $om-gray-700
      font-size: 14px
      line-height: 20px
</style>
